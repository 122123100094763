@font-face {
    font-family: "QuicksandMedium";
    src: url("../assets/fonts/Quicksand/Quicksand-Medium.ttf");
}

@font-face {
    font-family: "QuicksandRegular";
    src: url("../assets/fonts/Quicksand/Quicksand-Regular.ttf");
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    height: 100%;
}

.main-form {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75%;
    margin-top: 100px;
}

.form-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* padding: 2vw 4vw; */
    width: 100%;
    max-width: 700px;
    border-radius: 10px;
}

.form-container h3 {
    display: flex;
    color: rgb(15, 15, 15);
    font-weight: 800;
    margin-bottom: 10px;
    font-size: 30px;
    font-family: "QuicksandRegular";
}

.form-container input,
.form-container textarea {
    border: 0;
    margin: 10px 0;
    padding: 5px;
    outline: none;
    font-size: 16px;
    border-radius: 5px;
    background-color: transparent;
}

.form-container textarea {
    resize: none;
    width: 100%;
    height: 125px;
}

.user-name {
    display: flex;
    justify-content: space-between;
}

.input-box {
    display: flex;
    justify-content: left;
    align-items: center;
    background: rgba(255, 255, 255, 0.4);
    margin: 2px 0;
    padding: 0 20px;
    border-radius: 10px;
    border: solid 2px;
    border-color: transparent;
    transition: .3s;
}

.input-box.error {
    border-color: rgb(213, 57, 57);
    background: rgb(217, 149, 149);
}

.input-box i {
    padding-right: 5px;
}

 .submit-btn {
    display: flex;
    justify-content: center;
}

.submit-btn p {
    color: rgb(245, 245, 245);
    background: rgb(15, 15, 15);
    border-radius: 20px;
    padding: 12px 30px;
    cursor: pointer;
    font-family: "QuicksandMedium";
    transition: background 0.3s ease;
}

@media (max-width: 600px) {
    .main-form {
        margin-top: 30px;
    }

    .form-container {
        width: 80%;
    }

    .user-name {
        display: block;
    }
  }
