@font-face {
  font-family: "QuicksandMedium";
  src: url("../assets/fonts/Quicksand/Quicksand-Medium.ttf");
}

@font-face {
  font-family: "QuicksandRegular";
  src: url("../assets/fonts/Quicksand/Quicksand-Regular.ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  height: 100vh;
}

.logo-container {
  display: flex;
  justify-content: center;
  background-color: rgb(31,33,34);
}

.logo-container img {
  width: 100px;
	max-width: 100%;
	height: auto;
}


.background-image {
	background-image: url('../assets/mountains_background.webp');
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
}

.mid-container {
  position: relative;
	z-index: 1;
  display: flex;
  flex-direction: column;
  margin-top: 175px;
  align-items: center;
  align-self: center;
  text-align: center;
  height: calc(100vh - 350px);
}

.main-container h1 {
  font-size: 5rem;
  font-family: "QuicksandRegular";
}

.main-container p {
  font-weight: normal;
  font-family: "QuicksandMedium";
  padding-left: 20px;
  padding-right: 20px;
}

.social-networks {
  display: flex;
  justify-content: right;
  margin-top: 20px;
}

.social-networks img {
  width: 30px;
	max-width: 100%;
	height: auto;
}

.social-networks a {
  text-decoration: none;
  margin-right: 20px;
  margin-left: 20px;
}

.contact-btn {
  margin-top: 60px;
}

.contact-btn a {
  padding: 16px 35px;
  text-decoration: none;
  color: white;
  font-size: 1.1rem;
  background: rgb(15,15,15);
  border-radius: 20px;
  font-family: "QuicksandMedium";
}


@media (max-width: 600px) {
  .main-container h1 {
    font-size: 3rem;
  }
}
