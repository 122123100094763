.logo-container {
    display: flex;
    justify-content: center;
    background-color: rgb(31,33,34);
  }

.logo-container img {
    width: 90px;
    max-width: 100%;
    height: auto;
}
